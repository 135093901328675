import type { MetaFunction } from "@remix-run/node";
import { useLoaderData } from "@remix-run/react";
import { PostPreview } from "~/common/components/PostPreview";
import Sidebar from "~/common/components/sidebar";
import {
  fetchPopularTags,
  fetchPosts,
} from "~/common/repositories/post.repository";
import { formatTitle } from "~/common/utils/formatTitle";

export const meta: MetaFunction = () => {
  return [
    { title: formatTitle("Home") },
    { name: "description", content: "Welcome to Year29" },
  ];
};

export async function loader() {
  const posts = await fetchPosts();

  const popularTags = await fetchPopularTags();

  return {
    posts,
    popularTags: popularTags.map((tagInfo) => tagInfo.tag),
  };
}

export default function Index() {
  const { posts, popularTags } = useLoaderData<typeof loader>();

  return (
    <main className="main pt-4">
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            {posts.length > 0 ? (
              posts.map((post) => <PostPreview key={post.slug} post={post} />)
            ) : (
              <div>No Posts</div>
            )}
          </div>

          <Sidebar popularTags={popularTags} />
        </div>
      </div>
    </main>
  );
}
